<template>
<div>
    <div>
        <hr class="lbluehr" />
    </div>
    <div class="fream">
        <el-input placeholder="请输入用户名" v-model="usrdata.uid" clearable>
        </el-input>
    </div>
    <div class="fream">
        <form ><el-input placeholder="请输入密码" v-model="usrdata.password" show-password></el-input></form>
    </div>
    <div>
        <el-button class="btn fream" type="primary" :loading="loading" @click="login" plain>登录
        </el-button>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import {
    Notification
} from "element-ui";
import url from "@/serviceAPI.config.js";
import {
    setStore
} from "../../utils/storage";
import axios from "axios";
Vue.component(Notification.name, Notification)
export default {
    data() {
        return {
            loading: false,
            usrdata: {
                uid: "",
                password: "",
            },
        };
    },
    methods: {
        login() {
            this.loading = true;
            axios({
                    url: url.login,
                    method: "post",
                    data: {
                        userName: this.usrdata.uid,
                        password: this.usrdata.password,
                    },
                })
                .then((response) => {
                    if (response.data.code == 200 && response.data.message) {
                        setTimeout(() => {
                            this.loading = false;
                            Notification({
                                type: "success",
                                title: "登录成功",
                            });
                        }, 1000);
                        let token = response.data.token;
                        setStore("token", token);
                        this.$router.push("/admin");
                    } else {
                        console.log("login failed");
                        setTimeout(() => {
                            this.loading = false;
                            Notification({
                                type: "error",
                                title: "登录失败",
                            });
                        }, 1000);
                        // this.openLoading = false
                    }
                })
                .catch((error) => {
                    console.log("login failed");
                    setTimeout(() => {
                        this.loading = false;
                        Notification({
                            type: "error",
                            title: "登录失败",
                        });
                    }, 1000);
                });
        },
    },
};
</script>

<style scoped>
.lbluehr {
    border: none;
    height: 2px;
    background-color: rgb(64, 158, 255);
    width: 45vw;
    float: left;
    margin-top: -4px !important;
    z-index: 999;
}

.btn {
    width: 90vw;
    background-color: rgb(64, 158, 255);
    color: azure
}

.fream {
    margin-bottom: 1vh !important;
}

div {
    top: 0px !important;
}
</style>
